.nav {
  padding: 0 0 10*$pxToRem 0;

  ul {
    @include util-flex-row-align-children-center;
    @include config-type-body-lg;
    flex-wrap: wrap;
    text-align: center;
    margin: auto;
    padding: 27*$pxToRem 0;
    gap: 78*$pxToVw;

    @include media-lg-monitor-only {
      gap: 78*$pxToRem;
    }

    @include media-mobile-only {
      flex-direction: column;
      line-height: 2.5;
    }

    a {
      padding: 10*$pxToRem;
      font-weight: 700;
      color: $color-nav-idle;

      &:hover {
        color: $color-text;
      }
    }
  }

  #menu-home-wellness {
    justify-content: space-between;
    gap: revert;
  }

  .hr {
    margin: 0 auto;
  }
}

.nav-vertical {
  ul {
    @include config-type-body-lg;
    text-align: center;

    @include media-mobile-only {
      line-height: 2;
    }

    li {
      position: relative;
      padding: 18*$pxToRem 0;

      a {
        font-weight: 700;
      }

      &:not(:first-child)::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        height: 1*$pxToRem;
        max-width: 150*$pxToRem;
        transform: translateX(-50%);
        background: $color-gray-4;
      }
    }
  }
}

.nav-column-menu {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  max-width: 1920px !important;
  padding: 0 30*$pxToRem;
  gap: 20*$pxToRem;

  & > * {
    margin-bottom: 44*$pxToRem;
  }

  @include media-below(1600) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @include media-mobile-only {
    display: block;

    & > * {
      margin: auto;
      margin-bottom: 60px;
    }
  }

  a {
    display: block;
  }

  h2 {
    @include config-type-subheading;
  }

  & > div:not(:first-child) > h2 {
    @include media-mobile-only {
      margin-top: $padding-mobile;
    }
  }

  img {
    margin: 36*$pxToRem auto 30*$pxToRem auto;
  }
}
