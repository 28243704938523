/* gallery module: theme */

.case-box__images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
}

.no-link {
    color: #b4b4b4;
    cursor: default;
}

.no-link:hover {
    color: #b4b4b4;
}

.gallery-titles span {
    display: block;
    font-size: 18*$pxToRem;
}
.procedure-link {
    justify-self: right;
}
.patient-navigation {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

@include media-mobile-only {
    .gallery-links {
        justify-items: center;           
    }
    .procedure-link {
        justify-self: center;
    }
}