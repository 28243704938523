body,
input,
textarea,
button,
select,
.typography-body {
  @include config-type-body;
}

h1,
h2,
.typography-heading {
  @include config-type-heading;
}

h3,
.typography-subheading {
  @include config-type-subheading;
}

.typography-subheading-lg {
  @include config-type-subheading-lg;
  margin-bottom: 12*$pxToRem;
}

.typography-body-jumbo,
.accordion__content {
  font-size: $font-size-subheading*$pxToRem;
}

.typography-body-lg {
  @include config-type-body-lg;
}

.typography-credentials {
  @include config-type-body-lg;
  letter-spacing: getLetterSpacing(30);
  text-transform: none;
  font-weight: 400;
  padding-bottom: 24*$pxToRem;
}

h2 + .typography-credentials {
  margin-top: 10*$pxToRem;
}

.typography-ornamental {
  @include config-type-ornamental;
}

.typography-regular-case {
  text-transform: none;
}

.typography-heading-lg,
.typography-heading-lg-offset-1,
.typography-heading-lg-offset-2 {
  @include config-type-heading-lg;

  span {
    position: relative;

    &:first-child {
      z-index: 1;

      @include media-above-mobile {
        padding-left: 46*$pxToRem;
        margin-right: -150*$pxToRem;
      }

      &:before {
        @include config-ornament;
      }
    }

    & + span {
      z-index: 0;
      @include config-type-ornamental;
    }

    @include media-mobile-only {
      display: block;
      margin: auto;
      margin-top: $padding-mobile;
      text-align: center;

      & + span {
        margin-top: 0;
      }

      &:before {
        display: none !important;
      }
    }
  }
}

.typography-heading-lg-offset-1 {
  span {
    &:first-child {
      @include media-above-mobile {
        margin-right: -208*$pxToRem;
      }
    }
  }
}

.typography-heading-lg-offset-2 {
  span {
    &:first-child {
      @include media-above-mobile {
        // margin-right: -88*$pxToRem;
        margin-right: revert;
      }
    }
  }
}

.typography-heading-sm {
  @include config-type-heading-sm;
}

h2.typography-subheading + h3.typography-heading {
  margin-top: 5*$pxToRem;
}

p,
li,
td {
  // link inside the above
  a {
    font-weight: 500;
  }
}

strong,
b,
th,
strong a,
.typography-heavy {
  font-weight: 700;
}

small,
.typography-fine-print {
  @include config-type-fine-print;
}

// 
.typography-heading-container {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: max-content;
  padding-bottom: 8*$pxToRem;

  h3 {
    order: -1;
    margin-top: $offset-subheading-micro*$pxToRem;
    margin-bottom: $offset-subheading-micro*$pxToRem;
  }

  h2 {
    margin-top: 0 !important;
    position: relative;
    z-index: 2;
  }

  h4 {
    @include config-type-ornamental;
    position: relative;
    width: 100%;
    text-align: right;
    z-index: 1;
    margin: -16*$pxToRem 0 0 0;
  }

  & + p.typography-heavy {
    padding-right: 70*$pxToRem;

    @include media-mobile-only {
      padding-right: 0;
    }
  }
}

.text-align-center .typography-heading-container {
  margin: auto;

  h2 {
    width: auto;
  }
}
