// TODO: This is kind of a catch-all / misc file but these should probably be split out into their own modules

.container-floating-nav-box {
  nav ul li {
    padding: 12*$pxToRem !important;

    &:before {
      display: none !important;
    }
  }

  @include media-mobile-only {
    padding: $padding-mobile;
    margin-top: $padding-mobile;
  }

  @include media-above-mobile {
    position: absolute;
    bottom: -30*$pxToRem;
    right: -30*$pxToRem;
    padding: 60*$pxToRem 40*$pxToRem;
  }
}

.container-offset-bottom {
  @include media-above-mobile {
    transform: translateY(100%);
  }
}

.container-offset-left,
.container-offset-right {
  &.bg-texture-dark,
  &.bg-texture-dark-sm,
  &.bg-texture-light {
    & > * {
      position: relative;
      z-index: 1;
    }
  }

  @include media-above-mobile {
    position: relative;
    z-index: 2;
    margin-top: 240*$pxToVw !important;
    margin-bottom: 60*$pxToVw;
  }

  @include media-lg-monitor-only {
    margin-top: 240*$pxToRem !important;
    margin-bottom: 60*$pxToVw;
  }

  @media screen and
    (min-width: #{$media-breakpoint-tablet-portrait}px) and
    (max-width: #{$media-breakpoint-laptop}px)
  {
    margin-left: 24*$pxToRem !important;
    margin-right: 24*$pxToRem !important;
  }
}

.container-offset-left {
  @include media-above-mobile {
    padding-right: calc(#{72*$pxToVw} + #{$gutter-size-16});
    margin-right: $padding-thick-neg-vw;
    position: relative;
    z-index: 2;
  }

  @include media-lg-monitor-only {
    margin-right: $padding-thick-neg-rem !important;
  }
}

.container-offset-right {
  @include media-above-mobile {
    padding-left: calc(#{72*$pxToVw} + #{$gutter-size-16}) !important;
    margin-left: $padding-thick-neg-vw;
  }

  @include media-lg-monitor-only {
    margin-left: $padding-thick-neg-rem;
  }

  @include media-mobile-only {
    &.padding-section-content {
      padding-bottom: 0 !important;
    }
  }
}

.padding-default {
  .container-offset-left,
  .container-offset-right {
    &:not(.bg-texture-dark):not(.bg-texture-dark-sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.container-procedure-tile {
  position: relative;
  display: flex;
  flex-direction: column;

  & > * {
    position: relative;
    z-index: 5;
  }

  @include media-mobile-only {
    overflow: hidden;
  }

  .container-procedure-tile__content {
    @include media-above-mobile {
      min-height: 330*$pxToRem;
      max-width: 612*$pxToRem;

      h2 + p {
        margin-top: 20*$pxToRem;
      }
    }
  }

  .container-procedure-tile__toolbar {
    background: rgba($color-black, 0.25);
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 55*$pxToVw 100*$pxToVw;

    @include media-lg-monitor-only {
      padding: 55*$pxToRem 100*$pxToRem;
    }
  
    @include media-mobile-only {
      padding: $padding-mobile;
      gap: $padding-mobile;

      & > * {
        margin: auto;
      }
    }

    .button-learn-more {
      margin-left: auto;
    }
  }
}

.container-tiled-corner-box {
  margin: 50*$pxToRem 50*$pxToRem 50*$pxToRem 30*$pxToRem;

  .padding-xl2 {
    padding: $padding-default-vw;

    @include media-desktop-only {
      min-height: 520*$pxToVw;
    }

    @include media-lg-monitor-only {
      padding: $padding-default-rem;
      min-height: 520*$pxToRem;
    }
  }

  @include media-mobile-only {
    margin: 30px;

    .padding-xl2 {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  .container-tiled-corner-box__tiles {
    position: relative;

    & > * {
      position: relative;
      z-index: 1;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 80%;
      height: 78%;
      z-index: 0;
      background: $color-texture-ocean url(#{$imagesPath}bg-texture-ocean-1.jpg) center center / cover no-repeat;
    }

    &::before {
      top: -50*$pxToRem;
      right: -50*$pxToRem;
      width: 70%;
      background-image: url(#{$imagesPath}bg-texture-ocean-2.jpg);
    }

    &::after {
      bottom: -50*$pxToRem;
      left: -50*$pxToRem;
    }

    @include media-mobile-only {
      &::before {
        top: -30*$pxToRem;
        right: -30*$pxToRem;
      }
  
      &::after {
        bottom: -30*$pxToRem;
        left: -30*$pxToRem;
      }
    }
  }
}

.container-border-left,
.container-border-right {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: $border-thickness*$pxToRem;
    height: 100%;
    position: absolute;
    background: $color-accent;
    top: 0;
  }

  @include media-mobile-only {
    &::before {
      display: none;
    }
    
    &.padding-section-content {
      padding: 0 $padding-mobile;
    }

    &:first-child {
      margin-bottom: $padding-mobile;
    }

    &:not(:first-child) {
      margin-top: $padding-mobile;
    }
  }
}

.container-border-left {
  &::before {
    left: 0;
  }

  @include media-mobile-only {
    border-left: $border-thickness*$pxToRem solid $color-accent;
  }
}

.container-border-right {
  &::before {
    right: 0;
  }

  @include media-mobile-only {
    border-right: $border-thickness*$pxToRem solid $color-accent;
  }
}

.container-box-sizing {
  box-sizing: border-box;

  @include media-above-mobile {
    padding-bottom: 90*$pxToRem;
  }

  svg {
    display: block;
    margin: 0 auto 50*$pxToRem auto;
    width: 100%;
    height: 36*$pxToRem;
    max-width: 272*$pxToRem;
  }
}

.container-review-tiles {
  list-style: none;
  padding: 14*$pxToRem 0 100*$pxToVw 0;

  @include media-lg-monitor-only {
    padding-bottom: 100*$pxToRem;
  }

  a {
    display: block;
    text-align: center;
    background: $color-gray-1;
    padding: 54*$pxToRem 32*$pxToRem 46*$pxToRem 32*$pxToRem;
    overflow: hidden;
    position: relative;

    @include media-mobile-only {
      padding: 32px $padding-mobile;
    }

    svg {
      display: block;
      width: 65*$pxToRem;
      height: 40*$pxToRem;
      margin: 0 auto 6*$pxToRem auto;
      fill: $color-accent;
      transition: fill $transition-default;
    }

    span:not(.sr-only) {
      display: block;
      @include config-type-subheading-lg;
      color: $color-text-inverted;
      transition: color $transition-default;

      @include media-above-mobile {
        font-size: 30*$pxToRem;
        margin: 40*$pxToRem auto 12*$pxToRem auto;
      }
    }

    // Accent color pipe ornament that animates on hover
    &::after {
      display: block;
      content: "";
      position: absolute;
      background-color: $color-accent;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      transition: transform $transition-default;
      // offset colored background box by default (clipped by parent)
      transform: translateX(-100%);
    }
  
    &:hover {
      span {
        color: $color-text;
      }

      svg {
        fill: $color-text;
      }

      &::after {
        // remove offset (i.e. colored box appears to slide all the way out)
        transform: translateX(0);
      }
    }
  }
}

.container-memberships {
  .bg-texture-light {
    min-height: 156*$pxToRem;

    &:before {
      opacity: 0.5;
    }

    & > * {
      position: relative;
      z-index: 1;
    }

    &.border-radius-md {
      &:before {
        border-radius: 24*$pxToRem;
      }
    }
  }
}

.container-full-spread {
  max-width: 1920px;
  position: relative;
  display: flex;
  align-items: flex-end;
  margin: auto;
  box-sizing: border-box;

  .typography-heading-container {
    margin-top: 30*$pxToRem;

    & + p {
      margin-top: 10*$pxToRem;
    }
  }

  p + .button {
    margin-top: 10*$pxToRem;
  }

  .container-full-spread__content {
    position: relative;

    .padding-section-content > * {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include media-mobile-only {
    .bg-tinted:after {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  @include media-above-mobile {
    .width-1780 {
      position: relative;
      z-index: 1;
      // display: grid;
      // grid-template-columns: 207fr 238fr;
      display: flex;
      margin-right: 0 !important;
      margin-left: auto !important;
    }

    .container-full-spread__content {
      padding: 80*$pxToVw 0;
      width: 46.517%;
    }

    &.container-full-spread--opposite {
      .width-1780 {
        justify-content: flex-end;
      }
    }
  }

  @include media-below(1200) {
    .bg-texture-light {
      display: none;
    }
  }

  @include media-above(1200) {
    .bg-texture-light {
      @include config-background-box;
      height: 76.75%;
      top: 0;
      left: 0;
    }

    padding-left: $padding-default-vw;
    padding-top: 100*$pxToVw;
  }

  @include media-lg-monitor-only {
    padding-left: $padding-default-rem;
    padding-top: 100*$pxToRem;

    .container-full-spread__content {
      padding: 80*$pxToRem 0;
    }
  }
}

.embed-responsive {
	position:relative; 
	display:block; 
	height:0px; 
	padding:0px; 
	overflow:hidden; 
	margin-bottom:26px;
}
.embed-responsive.embed-responsive-4x3, 
.embed-responsive.embed-responsive-4by3 {
	padding-bottom:75%;
}
.embed-responsive.embed-responsive-16x9, 
.embed-responsive.embed-responsive-16by9 {
	padding-bottom:56.25%;
}
.embed-responsive .embed-item, 
.embed-responsive iframe, 
.embed-responsive embed, 
.embed-responsive object {
	position:absolute; 
	top:0px; 
	bottom:0px; 
	left:0px; 
	width:100%; 
	height:100%; 
	border:0px none;
}

