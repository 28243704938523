/* menu module: theme */

// Navigation Menu and Company Info modal that slides into view
#menu {
  position: fixed;
  display: grid;
  grid-template-columns: 6.5fr 3.5fr;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%); // menu is translated off screen by default
  transition: transform $transition-aos-transform;
  overflow: auto;

  @include media-below-lg-monitor {
    grid-template-columns: 7fr 3fr;
  }

  @include media-below(1200) {
    grid-template-columns: 1fr;

    & > nav:first-child {
      padding-bottom: 200*$pxToRem;
    }
  }

  & > nav:first-child {
    background: $color-gray-1 url(#{$imagesPath}bg-texture-nav-dark.jpg) center center / cover no-repeat;
    height: 100%;
    max-height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease;
    position: relative;
    display: flex;
    flex-direction: column;
    // align-items: center;
    align-items: flex-start;
    box-sizing: border-box;

    a {
      display: block;
      color: $color-gray-3;
      padding: 10*$pxToRem;

      &:focus,
      &:hover {
        color: $color-white;
      }

      &:focus + ul {
        opacity: 1;
        z-index: 9;
      }
    }

    @include media-desktop-only {
      .menu__home-link {
        display: none;
      }

      // First-level <ul>
      & > ul {
        // margin: 8vh 27vw 0 0;
        margin: 8vh 0 0 calc( 15% - 5rem);
        max-width: max-content;
        position: relative;
        padding-bottom: 120*$pxToRem;

        & > li > a {
          @include config-type-heading;
          font-size: 30*$pxToRem;

          @include media-below-lg-monitor {
            @include config-type-heading;
            font-size: 20*$pxToRem;
          }
        }

        // hide mobile subnav expand/collapse button
        button {
          display: none;
        }

        // Nested <ul>
        ul {
          position: absolute;
          opacity: 0;
          left: calc(100% - #{62*$pxToRem});
          margin-top: -84*$pxToRem;
          width: 25vw!important;
          max-width: 600*$pxToRem;
          box-sizing: border-box;
          padding-left: 5vw;
          padding-top: 25*$pxToRem;
          opacity: 0;
          z-index: -1;
          transition: opacity $transition-default;

          &:focus-within {
            opacity: 1;
            z-index: 9;
          }

          // Second-level <a>
          a {
            @include config-type-body-lg;
            padding: 11*$pxToRem 16*$pxToRem;
            display: flex;
            align-items: center;
            line-height: 1.2;

            @include media-below-lg-monitor {
              @include config-type-body-lg;
              font-size: 16*$pxToRem;
            }
            
          }
        }

        // First-level <li>
        & > li {
          position: relative;
          padding-right: 30*$pxToRem;
          
          &:hover,
          &:focus-within {
            // First-level <a>
            & > a {
              color: $color-white;
            }
    
            &::before {
              @include util-pseudo-layer;
              left: 100%;
            }
    
            // Nested <ul>
            ul {
              opacity: 1;
              z-index: 9;
            }
          }
        }
      }
    }

    @media screen and (min-width: #{$media-breakpoint-laptop + 1}px) and (min-height: 1080px) {
      // First-level <ul>
      & > ul {
        margin-top: 12vh;
        padding-bottom: 200*$pxToRem;

        & > li > a {
          padding: 25*$pxToRem 10*$pxToRem;
        }
      }
    }

    // Laptop size
    @include media-below-lg-monitor {
      
      & > ul > ul {
        padding-left: 1vw;

        a {
          line-height: 1.25;
        }
      }
    }
  }

  // Mobile
  @include media-below-desktop {
    & > nav:first-child {
      transition: none;
      align-items: flex-start;
      padding-top: 30px;
      padding-bottom: 50px;
      background-size: auto;
      background-position: 0 0;
      background-repeat: repeat-y;

      a {
        color: $color-gray-7;
      }

      // First-level <ul>
      & > ul {
        width: 100%;
        box-sizing: border-box;
        padding: $padding-mobile;

        // First-level <li>
        & > li {
          position: relative;

          a {
            @include config-type-subheading;
            width: calc(100% - 50px);
          }

          .menu__sub-menu-wrapper {
            overflow: hidden;
            height: 0;
            transition: height $transition-default;
          }

          // Nested <ul>
          ul {
            margin: 0;
            padding: 0 0 $padding-mobile $padding-mobile;
            opacity: 0;
            transition: opacity $transition-default;

            // Second-level <a>
            a {
              text-transform: none;
            }
          }

          // mobile expand/collapse sub-nav trigger
          button {
            background: transparent;
            width: 40px;
            height: 40px;
            position: absolute;
            right: -8px;
            top: 3px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before,
            &:after {
              display: block;
              content: '';
              width: 14px;
              height: 2px;
              background: $color-white;
              position: absolute;
              opacity: 0.5;
              transition: transform $transition-default,
                          opacity $transition-default;
            }

            &:after {
              transform: rotate(90deg);
            }
          }

          &.menu__sub-menu-expanded {
            & > a {
              color: $color-white;
            }

            ul {
              opacity: 1;
            }

            button {
              &:before {
                opacity: 1;
              }

              &:after {
                transform: rotate(0deg);
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  aside {
    @include util-align-children-center;
    background: $color-texture-light url(#{$imagesPath}bg-texture-nav-light.jpg) center center / cover no-repeat;
    opacity: 0;
    transition: opacity 0.8s ease 0.1s;
    position: relative;

    .menu__company-info {
      transition: opacity 0.8s ease 0.4s;
      opacity: 0;
      margin-top: auto;
      padding-top: 15vh;

      .logo {
        margin: 0 auto 73*$pxToRem auto;
      }

      address {
        padding: 0 30*$pxToRem;

        strong {
          @include config-type-body-lg;
          display: block;
        }

        a {
          @include config-type-body;
        }
      }
    }

    .menu__bottom {
      margin-top: auto;
      padding: 30*$pxToRem;
      padding-bottom: 60*$pxToVw;

      @include media-lg-monitor-only {
        padding-bottom: 60*$pxToRem;
      }
    }

    .legal {
      text-align: center;
      margin-top: 20*$pxToRem;

      nav ul {
        justify-content: center;
      }
    }

    .social {
      transition: opacity 0.8s ease 0.6s;
      opacity: 0;

      ul {
        justify-content: center;
      }
    }

    @include media-below(1200) {
      padding: 100*$pxToRem 0;

      .contact-phone {
        display: none;
      }

      .menu__company-info {
        padding-top: 0;
      }
    }

    @include media-below-desktop {
      transition: none;
      padding: 50*$pxToRem 0;

      .menu__company-info {
        transition: none;
      }

      .legal {
        display: none;
      }
    }
  }

  &__sub-menu-15 {
    column-count: 2;
    white-space: nowrap;
    gap: 4rem;
  }
}

// Hamburger / Close button
#menu-trigger {
  @include util-align-children-center;
  padding: 6*$pxToRem 20*$pxToRem;
  position: fixed;
  background: $color-white;
  // background: rgba($color-white, 0.975);
  // -webkit-backdrop-filter: blur(10px);
  // backdrop-filter: blur(10px);
  // filter: drop-shadow(2px 10px 50px rgba($color-black, 0.075));
  z-index: 9;
  transition: background-color 0.2s ease;

  @include media-below(1200) {
    outline-color: $color-text-inverted !important;
  }

  svg {
    transition: fill 0.2s ease;

    rect {
      transition: transform 0.3s ease;
      transform-origin: center;
    }
  }

  @include media-mobile-only {
    right: 0;
    top: 0;
    height: 60px;

    svg {
      width: 40*$pxToRem;
      height: 32*$pxToRem;
      fill: $color-text;

      &.menu-trigger__desktop {
        display: none;
      }
    }
  }

  @include media-above-mobile {
    top: 29*$pxToRem;
    right: 3.1vw;
    padding-top: 20*$pxToRem;
    padding-bottom: 15*$pxToRem;
  
    svg {
      width: 46*$pxToRem;
      height: 46*$pxToRem;
      fill: $color-text;
      transition: transform $transition-default,
                  fill $transition-default;
      transform: translateY(7*$pxToRem);

      &.menu-trigger__mobile {
        display: none;
      }

      rect:last-child {
        transform: scaleX(0.7) translateX(-10*$pxToRem);
      }
    }
    
    &:before,
    &:after {
      @include util-antialiasing-default;
      font-family: commuters-sans, sans-serif;
      font-size: 14*$pxToRem;
      text-transform: uppercase;
      margin: -29*$pxToRem 0 0 2*$pxToRem;
      text-align: center;
      display: block;
      position: absolute;
      transition: transform $transition-default,
                  opacity $transition-default;
      
    }

    &:before {
      content: 'Menu';
      opacity: 1;
    }

    &:after {
      content: 'Exit';
      opacity: 0;
    }
  }

  @media (hover: hover) {
    &:hover {
      svg {
        fill: $color-accent;

        rect:last-child {
          transform: scaleX(1) translateX(0);
        }
      }
    }

    &:active {
      svg {
        fill: $color-text;
        transition-duration: 0s;
      }
    }
  }
}

// Active Menu State
// menu will slide into view when document root has active class
// TODO: reconsider js prefix (in keeping with goal to always have parity between class/id prefix and css module name
.js-menu-is-active {
  #menu {
    transform: translateX(0);

    & > nav:first-child {
      opacity: 1;
      transition-duration: 1s;
    }

    aside {
      opacity: 1;

      .menu__company-info,
      .social {
        opacity: 1;
      }
    }
  }

  #menu-trigger {
    background: transparent;

    svg {
      rect {
        &:first-child {
          transform: rotate(45deg) translateY(6*$pxToRem);
        }

        &:last-child {
          transform: rotate(-45deg) translateY(-6*$pxToRem);
        }
      }
    }

    @include media-below(1200) {
      background: #2f2f2f;

      svg {
        fill: $color-text-inverted;
      }
    }

    @include media-above-mobile {
      &:before {
        opacity: 0;
      }
  
      &:after {
        opacity: 1;
      }

      svg {
        transform: translateY(13*$pxToRem);
      }

      &:hover,
      &:focus,
      &:focus-visible {
        svg {
          rect {    
            &:last-child {
              transform: rotate(-45deg) translateY(-6*$pxToRem);
            }
          }
        }
      }
    }
  }
}

// Prevents scrolling rest of page while menu modal is active
.js-viewport-frozen {
  overflow: hidden;

  // Prevents seeing UI below menu modal during mobile rubberbanding
  &.js-menu-is-active {
    @include media-mobile-only {
      .header,
      main,
      .footer,
      .menu-mobile-cta {
        visibility: hidden;
      }
    }
  }
}

.menu-mobile-cta {
  display: none;

  @include media-mobile-only {
    @include config-fixed-menu($color-accent);
    display: block;
    bottom: 0;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      padding: 0;

      li {
        flex: 1;

        &:not(:last-child) {
          border-right: 1*$pxToRem solid $color-white;
        }
      }

      a {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        height: 40px;

        &:hover {
          color: $color-text;
        }
      }
    }
  }
}

// temporary correx
.menu__sub-menu-wrapper .sub {
  cursor: pointer;
}

#menu > nav:first-child > ul ul {
  padding-left: 2.5vw;
  top: 30*$pxToRem;
  margin-top: 0;
  padding-top: 0px;
}

#menu > nav:first-child > ul ul ul {
  top: 0;
}

#menu > nav:first-child > ul ul .sub ul {
  display: none;
}

#menu > nav:first-child > ul ul .sub:hover ul {
  display: block;
}

// #menu > nav:first-child > ul ul a {
//   white-space: nowrap;
// }

#menu > nav:first-child > ul ul {
  width: initial;
}

@media screen and (min-width: 1025px) {
  #menu > nav:first-child > ul ul a {
    padding: 0 1rem 0.6875rem;
  }

#menu > nav:first-child > ul > li:hover ul, 
#menu > nav:first-child > ul > li:focus-within ul {
    top: 0;
    min-height: 225*$pxToRem;
}
}

@media ( max-width: 1440px ) {
  // #menu {
  //   overflow: hidden;
  // }
  #menu > nav:first-child > ul ul {
    padding-top: 0.5rem;
  }
  #menu > nav:first-child > ul ul .sub ul {
    padding-left: 0;
    left: 16.5rem;
  }
  #menu > nav:first-child > ul ul {
    width: 100%;
  }
  #menu > nav:first-child > ul > li > a {
  letter-spacing: 0.013em;
  line-height: 1;
  }  
}

@media ( min-width: 1025px ) {
  #menu > nav:first-child > ul li:nth-of-type(12) ul {
    column-count: 2;
    -moz-column-gap: 0;
    column-gap: 0;
    width: 35rem;
    }
}

@media ( max-width: 1025px ) {
  #menu > nav:first-child > ul ul .sub ul {
    left: 0;
  }
  #menu > nav:first-child > ul ul {
    width: 100%;
  }
  #menu > nav:first-child > ul > li > a {
    letter-spacing: 0.013em;
  }
}

@media screen and (min-width: 1441px) {

  #menu > nav:first-child > ul > li:hover ul, 
  #menu > nav:first-child > ul > li:focus-within ul {
      top: 1.5rem;
  }
  #menu > nav:first-child > ul ul .sub ul {
      top: 0;
  }
  #menu > nav:first-child > ul ul a {
    white-space: nowrap;
}
}
