/* columns module: core */
// TODO: consider if this would make more sense renamed to grid. .grid-1, .grid-2, etc.

// any element with a class that starts with "grid-"
.grid,
[class^="grid-"],
[class*=' grid-'] {
  display: grid;
}

// 2 column layout, equal widths
.grid-2-col {
  grid-template-columns: 1fr 1fr;
}

// 2 column layout with 5:7 ratio
.grid-2-col-1-2 {
  grid-template-columns: 1fr 2fr;
}

// 2 column layout with 7:5 ratio
.grid-2-col-2-1 {
  grid-template-columns: 2fr 1fr;
}

// 2 column layout with 5:7 ratio
.grid-2-col-5-7 {
  grid-template-columns: 5fr 7fr;
}

// 2 column layout with 7:5 ratio
.grid-2-col-7-5 {
  grid-template-columns: 7fr 5fr;
}

// 3 column layout, equal widths
.grid-3-col {
  grid-template-columns: 1fr 1fr 1fr;
}

// 4 column layout, equal widths
.grid-4-col {
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @include media-below-desktop {
    grid-template-columns: 1fr 1fr;
  }
}

.grid-2-col {
  grid-template-columns: 1fr 1fr;
}

@include media-above-mobile {
  .grid-switch-order {
    & > *:first-child {
      order: 1;
    }

    & > *:nth-child(2) {
      order: 0;
    }
  }
}

@include media-mobile-only {
  .grid-2-col,
  .grid-2-col-1-2,
  .grid-2-col-2-1,
  .grid-2-col-5-7,
  .grid-2-col-7-5,
  .grid-3-col,
  .grid-4-col {
    grid-template-columns: 1fr;
  }
}

.grid-no-stretch {
  align-items: flex-start;
}

.grid-vertically-center-children > * {
  align-self: center;
  justify-self: center;
}
