/* logo module: theme */

.logo {
  &,
  img,
  svg {
    display: block;
    width: 100%;
    max-width: 290*$pxToRem;
    height: auto;
    max-height: 84*$pxToRem;
  }

  // hack to improve svg antialiasing in Chrome
  svg {
    transform: scale(0.999);
  }
}

.logo-bar {
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  padding-top: 24*$pxToRem;
  gap: 50*$pxToRem;

  img,
  svg {
    width: auto;
    transition: opacity $transition-default;
    opacity: 0.45;
  }

  img {
    min-height: 73*$pxToRem;
  }

  a:hover {
    img,
    svg {
      opacity: 1;
    }
  }

  // horizontally center on mobile
  @include media-mobile-only {
    /* justify-content: center; */
    display: none;
  }

  &.logo-bar--tall {
    gap: 36*$pxToRem;

    img {
      min-height: 88*$pxToRem;
    }

    img,
    svg {
      opacity: 1;
    }
  }
}
