.contact-phone {
  @include util-antialiasing-default;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 140*$pxToRem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 135*$pxToRem;
  background: $color-accent;
  color: $color-text-inverted;
  font-size: 14*$pxToRem;
  line-height: 1.5;
  transition: background-color $transition-default;

  span {
    display: block;
    font-family: commuters-sans, sans-serif;
    text-transform: uppercase;
  }

  @include media-mobile-only {
    display: none;
  }

  &:hover {
    background: $color-black;
    color: $color-text-inverted;
  }
}
