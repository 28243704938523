/* footer module: theme */
// Note that magic numbers came from the design
.footer {
  display: flex;
  justify-content: flex-end;
  margin: auto;  
  max-width: 100% !important;
  
  .footer__content {
    width: 100%;
    margin: auto;
    max-width: 1640*$pxToRem !important;

    @include media-mobile-only {
      padding-bottom: 70*$pxToRem;

      .logo {
        margin: auto;
      }
    }
  }
  // footer-specific override of social module
  .social {
    margin-bottom: 36*$pxToRem;
    margin-right: -9*$pxToRem; // compensates for the padding-right of the last link

    ul {
      justify-content: flex-end;
    }

    @include media-mobile-only {
      margin: 20*$pxToRem auto;

      ul {
        justify-content: center;
      }
    }
  }
}

footer {
  background: $color-texture-light url(#{$imagesPath}bg-texture-light-wide.jpg) center center / cover no-repeat;
  max-width: unset !important;
  &> p {
    font-size: .875rem;
    line-height: 1.5;
    text-align: justify;
    text-align-last: center;
    @include media-mobile-only {
      margin-bottom: 3rem !important;
    }
  }
}


