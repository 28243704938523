.intro {
  max-width: 1920px;
  margin: auto;
  position: relative;
  overflow: hidden;

  & > * {
    position: relative;
    z-index: 1;
  }

  .bg-texture-light {
    @include config-background-box;
    top: $padding-default-vw;
    left: 0;
  }

  &.intro--offset-right {
    .bg-texture-light {
      left: auto;
    }
  }

  @include media-mobile-only {
    margin-top: 0 !important;

    .padding-top-100 {
      padding-top: 0 !important;
    }

    .bg-texture-light {
      display: none;
    }

    .intro__floating-text {
      margin-bottom: $padding-mobile;
    }

    &.intro--offset-right {
      .intro__floating-text,
      .padding-left-default {
        padding-left: 0 !important;
      }
    }

    .intro__content {
      order: 1;    
    }

    .logo-bar {
      /* padding-top: 50px !important; */
      display: none;
    }
  }
  
  @include media-above-mobile {
    p + .button {
      margin-top: 70*$pxToVw;
    }

    .logo-bar {
      padding-top: 70*$pxToVw;
    }

    .intro__floating-text {
      margin-bottom: 44*$pxToVw;
    }

    &.intro--offset-right {
      .logo-bar {
        padding-left: $padding-default-vw;
      }
    }
  }
  
  @include media-below-laptop {
    &.intro--offset-right {
      .intro__floating-text {
        padding-left: $padding-default-vw;
      }
    }
  }

  @include media-above-laptop {
    &.intro--offset-left,
    &.intro--offset-right {
      max-width: 1800*$pxToRem;

      .intro__floating-text {
        width: 180%;
      }
    }

    &.intro--offset-left {
      .bg-texture-light {
        left: $padding-default-vw;
      }

      .intro__content {
        padding-left: $padding-thick-vw;
        position: relative;
        z-index: 6;
      }
    }

    &.intro--offset-right {
      .bg-texture-light {
        right: $padding-default-vw;
      }

      .intro__content {
        padding-right: $padding-thick-vw;
      }

      .intro__floating-text {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        float: right;

        h2,
        h3 {
          width: 100%;
          max-width: 886px;
        }
      }

      .logo-bar {
        width: calc(100% + #{$padding-default-vw});
      }
    }
  }
  
  @include media-lg-monitor-only {
    .bg-texture-light {
      width: 398*$pxToRem;
      top: $padding-default-rem;
    }

    .intro__floating-text {
      margin-bottom: 44*$pxToRem;
    }

    &.intro--offset-left {
      .bg-texture-light {
        left: $padding-default-rem;
      }

      .intro__content {
        padding-left: $padding-thick-rem;
      }
    }

    &.intro--offset-right {
      .bg-texture-light {
        right: $padding-default-rem;
      }

      .intro__content {
        padding-right: $padding-thick-rem;
      }

      .logo-bar {
        width: calc(100% + #{$padding-default-rem});
        //margin-right: $padding-thick-neg-rem;
        padding-left: $padding-default-rem;
      }
    }

    p + .button {
      margin-top: 70*$pxToRem;
    }

    .logo-bar {
      padding-top: 70*$pxToRem;
    }
  }
}
