/* svg module: core */

// Ensures the full svg sprite never shows at the bottom of the page
#svg {
  top: 0;
  left: 0;
  position: absolute;
  height: 0;
  width: 0;
  visibility: hidden;
  overflow: hidden;
  z-index: -1;
}
