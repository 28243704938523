// TODO: consider moving this to core
.banner {
  @include util-align-children-center;
  background-color: $color-gray-2;
  max-width: 100%;
  color: $color-text-inverted;

  @include media-above-mobile {
    height: 80vh;
    max-height: 686*$pxToRem;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 1*$pxToRem;
      height: 34.2%;
      left: 50%;
      bottom: -70*$pxToVw;
      background: $color-gray-5;
      z-index: 5;
      opacity: 0;
      animation: fadeIn 1s 0.25s forwards;
    }
  }

  @include media-lg-monitor-only {
    &:before {
      bottom: -70*$pxToRem;
    }
  }

  @include media-mobile-only {
    margin-top: 60px; // compensate for fixed header
  }

  .banner__content {
    position: relative;
    z-index: 6;
    display: flex;
    flex-direction: column;

    h1 {
      order: 1;
      margin: 34*$pxToRem 0 24*$pxToRem 0;
    }
    h1 span {
      display: block;
      font-size: 24*$pxToRem;
    }

    h2 {
      @include config-type-subheading;
      order: 0;
      margin: 0;
    }
    @include media-mobile-only {
      h2 {
        display: none;
      }
    }

    h3,
    nav {
      order: 2;
    }
    @include media-mobile-only {  
      h3 {
        display: none;
      }
    }
  }

  // breadcrumbs
  nav {
    ul,
    ol {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 17*$pxToRem;

      li {
        position: relative;

        &:not(:last-child) {
          &::before {
            display: block;
            position: absolute;
            content: '|';
            right: -11*$pxToRem;
            top: 4*$pxToRem;
          }
        }

        a {
          font-weight: 300;
          font-size: 18*$pxToRem;
          display: block;
          padding: 5*$pxToRem 4*$pxToRem;
        }

        &:last-child {
          color: $color-accent;
          font-weight: 500;
          font-size: 18*$pxToRem;
          display: block;
          padding: 5*$pxToRem 4*$pxToRem;
        }

        @include media-mobile-only {
          a {
            font-size: 18*$pxToRem;
          }  
        }
      }
    }
  }
}

.banner--short {
  height: auto;
  max-height: auto;
  padding-top: 113*$pxToRem;
  padding-bottom: 56*$pxToRem;
}
