/* header module: theme */

.header {
  background: $color-white;
  width: 100%;
  
  .header__content {
    position: relative;
    min-height: 135*$pxToRem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6;
  }

  .contact-phone {
    position: fixed;
  }
}

.header__skip {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-white;
  padding: 6*$pxToRem 20*$pxToRem;
  position: fixed;
  top: 26*$pxToRem;
  left: 48*$pxToRem;
  transform: translateX(calc(-100% - 48px));
  height: 48*$pxToRem;
  transition: transform $transition-default,
              color $transition-default;
  z-index: 7;

  &:focus {
    transform: translateX(0);
    opacity: 1;
  }

  svg {
    margin-left: 16*$pxToRem;
    width: 32*$pxToRem;
    height: 22*$pxToRem;
    transition: fill $transition-default;
  }

  &:hover {
    svg {
      fill: $color-accent;
    }
  }
}

@include media-mobile-only {
  .header {
    @include config-fixed-menu($color-white);
    top: 0;
    height: 60px;

    .header__content {
      min-height: 60px;

      .logo {
        width: 152px;
        height: 44px;
        margin-left: 16px;
        margin-right: auto;
      }
    }
  }
}
