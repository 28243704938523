/* svg module: theme */

.svg__fill-accent {
  fill: $color-accent;
}

.svg__fill-white {
  fill: $color-white;
}

.svg__fill-gray-dark {
  fill: #272727;
}

.svg-shell {
  /* display: block; */
  fill: $color-accent;
  width: 30*$pxToRem;
  height: 30*$pxToRem;
  padding-right: 0.375rem;
  vertical-align: bottom;
}

.svg__fill-gray-6 {
  fill: $color-gray-6;
}
