/* button module: theme */

.button,
.button-play-video,
.button-learn-more {
  font-weight: 700;
  text-transform: uppercase;
  color: $color-text;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: max-content;
  min-height: 60*$pxToRem;

  &:hover {
    color: $color-text;
  }
}

.button,
.button-learn-more {
  padding: 0 24*$pxToRem;

  &:before,
  &:after {
    @include config-ornament;
    transition: transform $transition-default,
                opacity $transition-default;
  }

  &:before {
    opacity: 0;
    transform: translate(-40*$pxToRem, -50%);
  }

  &:after {
    right: 0;
    left: auto;
    opacity: 1;
    transform: translateX(0, -50%);
  }

  &:hover {
    &:before {
      opacity: 1;
      transform: translate(0, -50%);
    }
  
    &:after {
      opacity: 0;
      transform: translate(20*$pxToRem, -50%);
    }
  }
}

.button-learn-more {
  &:before,
  &:after {
    margin-top: 1*$pxToRem;
  }
}

.button {
  padding: 0 30*$pxToRem;
  min-height: 62*$pxToRem;

  &:before {
    transform: translate(-40*$pxToRem, -50%);
  }

  &:after {
    right: 6*$pxToRem;
  }

  span {
    position: relative;
    z-index: 1;
  }

  span.button__outline {
    overflow: hidden;
    z-index: 0;

    &,
    &:before,
    &:after {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      transition: opacity $transition-default;
      position: absolute;
    }

    &:before,
    &:after {
      content: '';
      outline: 1*$pxToRem solid $color-accent;
      outline-offset: -1*$pxToRem;
    }

    &:before {
      width: 60*$pxToRem;
    }

    &:after {
      opacity: 0;
    }
  }

  &:hover {
    &:before {
      opacity: 1;
      transform: translate(-7*$pxToRem, -50%);
    }

    span.button__outline {
      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.button-play-video {
  padding: 0 36*$pxToRem 0 90*$pxToRem;
  min-height: 62*$pxToRem;
  border-left: 1*$pxToRem solid $color-accent;
  overflow: hidden;

  svg {
    width: 25*$pxToRem;
    height: 25*$pxToRem;
    fill: $color-accent;
    display: block;
    position: absolute;
    left: 0;
    transition: transform $transition-default;
    transform: translateX(20*$pxToRem);
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 1*$pxToRem solid $color-accent;
    outline-offset: -1*$pxToRem;
    transition: transform $transition-default;
    // offset colored background box by default (clipped by parent)
    transform: translateX(calc(-100% + #{62*$pxToRem}));
  }

  &:hover {
    svg {
      transform: translateX(40*$pxToRem);
    }

    &:before {
      transform: translateX(-1*$pxToRem);
    }
  }
}






// .button,
// .button-icon {
//   width: max-content;
//   display: flex;
//   text-transform: uppercase;
//   position: relative;
//   overflow: hidden; // clip box for sliding background
//   padding: 0 26*$pxToRem;
//   min-height: 40*$pxToRem;
//   align-items: center;
//   justify-content: center;
//   z-index: 0;
//   color: $color-text;
//   font-weight: 500;
//   transition: color $transition-default;

// }

// .button {
//   // Plus sign preceding button text
//   &::before {
//     content: "+";
//     padding-right: 4*$pxToRem;
//   }
// }

// .padding-section-content .button:last-child {
//   @include media-mobile-only {
//     margin-bottom: $padding-mobile;
//   }
// }

// .button-icon {
//   svg {
//     display: block;
//     width: 16*$pxToRem;
//     height: 16*$pxToRem;
//     margin-right: 10*$pxToRem;
//   }
// }

.inverted a.button,
.inverted a.button-play-video,
.inverted a.button-learn-more,
a.button.inverted,
a.button-play-video.inverted,
a.button-learn-more.inverted {
  color: $color-text-inverted !important;

  &:hover {
    color: $color-text-inverted !important;
  }
}
