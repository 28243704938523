.legal {
  @include config-type-fine-print;
  text-align: right;

  p {
    &:not(:last-child) {
      margin-bottom: 8*$pxToRem;
    }

    & + p {
      margin-top: 8*$pxToRem;
    }
  }

  address {
    margin-bottom: 24*$pxToRem;

    a {
      // @include config-type-body;
      text-transform: uppercase;
    }
  }

  ul {
    margin-left: auto;
    justify-content: flex-end;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    display: flex;
    text-transform: uppercase;
    margin-top: 0;
    margin-right: -4*$pxToRem;  // compensates for the padding-right of the last link

    li {
      &:not(:last-child)::after {
        content: "|";
      }

      a {
        padding: 0 4*$pxToRem;
      }
    }
  }

  @include media-mobile-only {
    text-align: center;

    ul {
      justify-content: center;
    }
  }
}
