// Triple hyphens on lines 1 and 2 are required by Jekyll in order to
// process SCSS into CSS.
// 
// Note that your code editor may syntax highlight the first import statement
// below as being in an error state due to the hyphens above which are part of
// Jekyll syntax and not SCSS syntax. An invalid syntax error, especially on
// the first meaningful line of code, might be a false positive.

// dependencies imports
@import '_modules/core/_utils';
@import '_modules/core/_media';
@import '_modules/theme-seychelles/_config';
@import '_modules/theme-seychelles/typography';

// core module imports
@import '_modules/core/align';
@import '_modules/core/base';
@import '_modules/core/bg';
@import '_modules/core/container';
@import '_modules/core/gutter';
@import '_modules/core/grid';
@import '_modules/core/inverted';
@import '_modules/core/margin';
@import '_modules/core/padding';
@import '_modules/core/section';
@import '_modules/core/sr-only';
@import '_modules/core/svg';
@import '_modules/core/width';

// theme module imports
@import '_modules/theme-seychelles/base';
@import '_modules/theme-seychelles/accordion';
@import '_modules/theme-seychelles/aos';
@import '_modules/theme-seychelles/banner';
@import '_modules/theme-seychelles/bg';
@import '_modules/theme-seychelles/border-radius';
@import '_modules/theme-seychelles/button';
@import '_modules/theme-seychelles/column';
@import '_modules/theme-seychelles/contact-phone';
@import '_modules/theme-seychelles/container';
@import '_modules/theme-seychelles/footer';
@import '_modules/theme-seychelles/gallery';
@import '_modules/theme-seychelles/header';
@import '_modules/theme-seychelles/hr';
@import '_modules/theme-seychelles/img';
@import '_modules/theme-seychelles/intro';
@import '_modules/theme-seychelles/legal';
@import '_modules/theme-seychelles/logo';
@import '_modules/theme-seychelles/margin';
@import '_modules/theme-seychelles/menu';
@import '_modules/theme-seychelles/nav';
@import '_modules/theme-seychelles/padding';
@import '_modules/theme-seychelles/social';
@import '_modules/theme-seychelles/svg';
@import '_modules/theme-seychelles/width';


@include media-mobile-only {
	#userwayAccessibilityIcon {
		bottom: 3rem;
	}
}
