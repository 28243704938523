[data-aos="fade-in-btm"] {
  opacity: 0;
  transform: translateY($aos-slide-distance);
  transition: opacity $transition-aos-opacity,
              transform $transition-aos-transform;
}

.js-intersection-observed[data-aos="fade-in-btm"] {
  opacity: 1;
  transform: translateY(0);
}

[data-aos="fade-in"] {
  opacity: 0;
  transition: opacity $transition-aos-opacity;
}

.js-intersection-observed[data-aos="fade-in"] {
  opacity: 1;
}
