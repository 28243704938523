/* bg module: theme */

// Cover photo backgrounds

.bg-cover-photo {
  background-color: $color-gray-2;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-video {
  position: relative;
  overflow: hidden;

  video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    pointer-events: none;
    user-select: none;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    height: auto;
    z-index: 3;
  }
}

.bg-tinted,
.bg-tinted-less,
.bg-tinted-more,
.bg-tinted-80,
.bg-tinted-max {
  &::after {
    @include util-pseudo-layer;
    z-index: 4;
    background: rgba($color-black, 0.3);
  }

  &:not(.banner) > :first-child {
    position: relative;
    z-index: 5;
  }
}

.bg-tinted-less::after {
  background: rgba($color-black, 0.1);
}

.bg-tinted-more::after {
  background: rgba($color-black, 0.5);
}

.bg-tinted-80::after {
  background: rgba($color-black, 0.8);
}

.bg-tinted-max::after {
  background: rgba($color-black, 0.9);
}

.bg-decal {
  overflow: hidden;

  & > * {
    position: relative;
    z-index: 1;
  }
}

.bg-gray-1 {
  background-color: $color-gray-1;
}

.bg-gray-2 {
  background-color: $color-gray-2;
}

.bg-gray-10 {
  background-color: $color-gray-10;
}

.bg-gray-11 {
  background-color: $color-gray-11;
}

.bg-office-dark,
.bg-texture-light,
.bg-texture-dark,
.bg-texture-dark-sm {
  background: center center / cover no-repeat;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.bg-office-dark {
  background-color: $color-gray-1;
  background-image: url(#{$imagesPath}bg-office-dark.jpg);

  &:before {
    background: $color-gray-1;
    opacity: 0.9;
  }
}

.bg-texture-light {
  background-color: $color-texture-light;
  background-image: url(#{$imagesPath}bg-texture-light.jpg);

  &:before {
    background: $color-white;
    opacity: 0.8;
  }
}

.bg-texture-dark,
.bg-texture-dark-sm {
  background-color: $color-texture-dark;
  background-image: url(#{$imagesPath}bg-texture-dark.jpg);

  &:before {
    background: $color-texture-dark;
    opacity: 0.8;
  }
}

.bg-texture-dark-sm {
  background-image: url(#{$imagesPath}bg-texture-dark-sm.jpg);
}

.bg-shell {
  background: url(#{$imagesPath}bg-shell.png) 0 100*$pxToRem no-repeat;
}
