/* base module: theme */

p,
h4,
h5,
h6,
blockquote,
ul,
ol,
table {
  margin-top: $content-spacing*$pxToRem;
  margin-bottom: $content-spacing*$pxToRem;

  & + .button,
  & + .button-icon {
    margin-top: ($content-spacing * 1.33)*$pxToRem;

    @include media-mobile-only {
      margin-top: $content-spacing*$pxToRem;
    }
  }

  @include media-mobile-only {
    margin-top: $content-spacing-mobile*$pxToRem;
    margin-bottom: $content-spacing-mobile*$pxToRem;
  }
}

h2,
.typography-heading-container:not(:first-child) {
  margin-top: (($content-spacing * 2) - ($offset-heading / 2))*$pxToRem;
}

h2 {
  margin-bottom: ($offset-heading / 2)*$pxToRem;

  @include media-mobile-only {
    margin-top: ($content-spacing-mobile * 2)*$pxToRem;
    margin-bottom: -6*$pxToRem;
  }

  span {
    margin-bottom: $offset-subheading-micro*$pxToRem;
  }

  & + div {
    margin-top: $content-spacing*$pxToRem;
  }

  @include media-above-mobile {
    &:first-child {
      margin-top: #{$offset-heading*$pxToRem};

      span {
        margin-top: #{-$offset-subheading-micro*$pxToRem};
      }
    }

    &:last-child {
      margin-bottom: #{$offset-heading*$pxToRem};
    }
  }
}

h2,
h3,
.typography-heading-container {
  & + p.typography-heavy {
    padding-right: 13%;

    @include media-mobile-only {
      padding-right: 0;
    }
  }
}

address {
  font-style: normal;
}

blockquote:not(figure blockquote) {
  margin-left: 0;
  color: $color-gray-2;
  position: relative;

  &::before {
    display: block;
    width: $border-thickness*$pxToRem;
    height: 100%;
    position: absolute;
    background: $color-accent;
    content: '';
    transform: translateX(-1.7em);
  }
}

//p a:hover {
//  color: $color-text;
//  text-decoration: underline;
//}
p a {
  color: $color-link;
  text-decoration: none;
  transition: color $transition-default;

  &:hover {
    color: $color-link-hover;
  }
}

// ul:not(nav ul):not(.accordion),
// ol:not(nav ol):not(.accordion),
blockquote:not(figure blockquote) {
  padding-left: ($content-spacing + ($content-spacing / 2))*$pxToRem;
}

@include media-mobile-only {
 .order {
    order: 2;
 }
}

// // list item not inside of a nav or part of accordion structure
// ul:not(nav ul):not(.accordion),
// ol:not(nav ol):not(.accordion) {
//   list-style: none;

//   li {
//     & + li {
//       margin-top: #{0.25 * ($line-height-body / $font-size-body)}em;
//     }

//     &::before {
//       display: block;
//       position: absolute;
//     }
//   }
// }

// // list item not inside of a nav or part of accordion structure
// ul li:not(nav li):not(.accordion > li) {
//   &::before {
//     content: '';
//     width: 0.375em;
//     height: 0.375em;
//     border-radius: 100%;
//     background: $color-accent;
//     transform: translate(-1.75em, 0.65em);
//   }
// }

// ol:not(nav ol):not(.accordion) {
//   counter-reset: li;

//   li {
//     &::before {
//       counter-increment: li;
//       font-weight: 700;
//       content: '.' counter(li);
//       color: $color-accent;
//       text-align: right;
//       direction: rtl;
//       transform: translateX(-1.75em);
//     }
//   }
// }


// li::before {content: "•"; color: red;
//   display: inline-block; width: 1em;
//   margin-left: -1em}


// .example ol li::before {content: counter(li); color: red;
//   display: inline-block; width: 1em; margin-left: -1.5em;
//   margin-right: 0.5em; text-align: right; direction: rtl}



// TODO: maybe move this to animation file
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
